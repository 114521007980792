export const environment = {
  production: true,
  urlbase: '/',
  auth: 'keycloak',
  backend: 'real',
  grafana: '',
  keycloak: {
    'url': '/auth',
    'realm': 'Braddell',
    'clientId': 'empusa',
  },
  //the currency used
  currency: 'S$',
  show_acess_group: false
};
